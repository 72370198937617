import styled from "@emotion/styled";

const Introduction = styled.p`
  font-size: 14px;
  line-height: 20px;
  font-style: italic;

  @media screen and (min-width: 768px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

export default Introduction;
