// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-art-in-progress-js": () => import("./../../../src/pages/art-in-progress.js" /* webpackChunkName: "component---src-pages-art-in-progress-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-in-person-js": () => import("./../../../src/pages/in-person.js" /* webpackChunkName: "component---src-pages-in-person-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inspiration-js": () => import("./../../../src/pages/inspiration.js" /* webpackChunkName: "component---src-pages-inspiration-js" */),
  "component---src-pages-koordinaten-js": () => import("./../../../src/pages/koordinaten.js" /* webpackChunkName: "component---src-pages-koordinaten-js" */),
  "component---src-pages-sal-vitae-js": () => import("./../../../src/pages/sal-vitae.js" /* webpackChunkName: "component---src-pages-sal-vitae-js" */),
  "component---src-pages-salive-js": () => import("./../../../src/pages/salive.js" /* webpackChunkName: "component---src-pages-salive-js" */),
  "component---src-pages-shining-stones-js": () => import("./../../../src/pages/shining-stones.js" /* webpackChunkName: "component---src-pages-shining-stones-js" */),
  "component---src-pages-street-art-installation-js": () => import("./../../../src/pages/street-art-installation.js" /* webpackChunkName: "component---src-pages-street-art-installation-js" */),
  "component---src-pages-vase-js": () => import("./../../../src/pages/vase.js" /* webpackChunkName: "component---src-pages-vase-js" */),
  "component---src-pages-zauberflasche-js": () => import("./../../../src/pages/zauberflasche.js" /* webpackChunkName: "component---src-pages-zauberflasche-js" */)
}

