import styled from "@emotion/styled";

const Text = styled.p`
  font-size: 18px;
  line-height: 26px;
  margin-top: 1rem;

  @media screen and (min-width: 768px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export default Text;
